import { createApp } from 'vue'
import store from './store'

import Helper from './utils/helper'
import { fetchAddOnABTLanguageCardConfig } from 'public/src/pages/common/add-on/utils/fetcher.js'
let instance
if(typeof window !== 'undefined') {
  requestIdleCallback(() => {
    window.preFetchAddOnABTLanguageCardConfig = fetchAddOnABTLanguageCardConfig()
  })
}

export default {
  open(config) {
    if (typeof window === 'undefined') return

    if (instance) {
      return instance.open(config)
    }

    return new Promise((resolve) => {
      import(/* webpackChunkName: "add_on_v2" */ './container.vue').then((components) => {
        const appDom = document.createElement('div')
        const app = createApp(components.default)
        app.use(store)
  
        const vm = app.mount(appDom)
        document.body.appendChild(vm.$el)
        const comp = vm
        comp.open(config)
        instance = comp

        resolve(instance)
      })
    })
  },
  updateOpts(options) {
    if (!instance) return
    instance.updateOpts(options)
  },
  close() {
    if (!instance) return
    instance.handleClose()
  },
  Helper,
}
